/*!

 =========================================================
 * Material Dashboard Dark Edition - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-dark
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

 @import "partials/dashboard/core/variables";
 @import "partials/dashboard/core/mixins";
 @import "partials/dashboard/core/core-bootstrap";

 // Core Components
 @import "partials/dashboard/core/buttons";
 @import "partials/dashboard/core/checkboxes";
 @import "partials/dashboard/core/radios";
 @import "partials/dashboard/core/forms";
 @import "partials/dashboard/core/input-group";
 @import "partials/dashboard/core/images";
 @import "partials/dashboard/core/navbar";
 @import "partials/dashboard/core/alerts";
 @import "partials/dashboard/core/headers";
 @import "partials/dashboard/core/type";
 @import "partials/dashboard/core/tabs";
 @import "partials/dashboard/core/tooltip";
 @import "partials/dashboard/core/popover";
 @import "partials/dashboard/core/dropdown";
 @import "partials/dashboard/core/togglebutton";
 @import "partials/dashboard/core/ripples";
 @import "partials/dashboard/core/footers";
 @import "partials/dashboard/core/sidebar-and-main-panel";
 @import "partials/dashboard/core/fixed-plugin";
 @import "partials/dashboard/core/tables";
 @import "partials/dashboard/core/misc";
 @import "partials/dashboard/core/social-buttons";

@import "partials/dashboard/core/cards";
@import "partials/dashboard/core/cards/card-stats";
@import "partials/dashboard/core/cards/card-profile";
@import "partials/dashboard/core/cards/card-plain";

 //plugin scss
 @import "partials/dashboard/core/plugins/animate";
 @import "partials/dashboard/core/plugins/chartist";
 @import "partials/dashboard/core/plugins/perfect-scrollbar";

 @import "partials/dashboard/core/responsive";

 // dark edition
 @import "partials/dashboard/core/dark-edition";

.dark-edition {
  // Variables
  @import 'dark-edition/dark-variables';

  background-color: $dark-background;

  // Mixins
  @import 'dark-edition/dark-mixins';

  // Buttons
  @import 'dark-edition/dark-buttons';

  // Sidebar
  @import 'dark-edition/dark-sidebar';

  // Navbar
  @import 'dark-edition/dark-navbar';

  // Footer
  @import 'dark-edition/dark-footer';

  // Card
  @import 'dark-edition/dark-card';

  // Tables
  @import 'dark-edition/dark-tables';

  // Checkboxes
  @import 'dark-edition/dark-checkboxes';

  // Radios
  @import 'dark-edition/dark-radios';

  // Tabs
  @import 'dark-edition/dark-tabs';

  // Tabs
  @import 'dark-edition/dark-popover-tooltip';

  // Forms
  @import 'dark-edition/dark-forms';

  // Fixed Plugin
  @import 'dark-edition/dark-fixed-plugin';

  // Dropdown
  @import 'dark-edition/dark-dropdown';

  // Colors
  @import 'dark-edition/dark-colors';

  // Alerts
  @import 'dark-edition/dark-alert';

  // Animation Keyframe
  @import 'dark-edition/dark-heartbeat-animation';
}
